import { parse } from '../utility/queryStringParser';
import {
  eventBus,
  betslipUtility,
  busService,
  localGameConfig,
  errorTicketHandler,
  ticketCheck,
  successTicketHandler,
  formatFirstBallColor,
  sortShortcuts,
} from '../utility';
import { isNil, forEach, range, omit, merge, cloneDeep } from 'lodash';
import types from './mutationTypes';
import { ticketAPI } from '@/api';
import * as modules from './modules';
import { ConfigurationService } from '@nsftx/games-config';
import * as crashTranslationsPackage from '@nsftx/i18n-crash-cash';
import * as luckySixTranslationsPackage from '@nsftx/i18n-lucky-six';
import * as racersTranslationsPackage from '@nsftx/i18n-games-racers';
import { ticketMapper } from '@nsftx/games-sdk-js/src/utility';

const removeLocaleSuffix = (locale) => locale?.split('-')[0];

const queryParams = parse(window.location.search) || {};
const tenantId = queryParams.tenantId;
const locale = removeLocaleSuffix(queryParams.locale);

export default {
  /*
   * Handle key events (shortcuts)
   */
  async handleGlobalShortcuts({ dispatch, getters }, payload) {
    const key = payload.ctrlKey ? `ctrl + ${payload.key.toLowerCase()}` : payload.key.toLowerCase();
    const shortcut = getters.getGameGetter({ getter: 'localGameConfig' }).shortcuts[key];
    const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
    if (!shortcut) return;

    switch (shortcut.action) {
      case 'resetTicket':
        // Reset all
        await dispatch('clearBetslip');
        eventBus.$emit('resetInput');
        eventBus.$emit('focusFirstBettingInput');
        dispatch('runAction', {
          action: 'handleGameShortcuts',
          payload: { key: defaultShortcut?.shortcut },
        });
        break;
      case 'results':
        eventBus.$emit('getResults');
        break;
      // Focus first bet
      case 'focusFirstBet':
        eventBus.$emit('focusFirstBet');
        break;
      case 'editStakePerBet':
        eventBus.$emit('focusStakePerBet');
        break;
      case 'editFuturePerTicket':
        eventBus.$emit('focusFutureRounds');
        break;
      case 'focusFirstInput':
        eventBus.$emit('focusFirstBettingInput');
        break;
      case 'toggleEditMode':
        if (getters.getGameGetter({ getter: 'editBetModeFeatureAllowed' })) {
          eventBus.$emit('toggleEditMode');
        }
        break;
      case 'deleteBet':
        eventBus.$emit('deleteBet');
        break;
      default:
    }
  },
  async showTicketPreview({ commit, dispatch, state }, { ticket, ticketVersion }) {
    if (isNil(ticket)) return;

    if (state.currentActiveGame === 'luckysix') {
      ticket.bets.forEach((bet) => {
        if (bet.type === 5) {
          bet.cleanValue = bet.value;
          bet.value = formatFirstBallColor(bet);
        }
      });
    }
    const ticketPreviewData = await dispatch('mapTicketForPreview', { ticket, ticketVersion });
    commit(types.SET_TICKET_PREVIEW_DATA, ticketPreviewData);
    commit(types.SET_TICKET_PREVIEW_TICKET, { ticket: ticket, ticketVersion: ticketVersion });
    commit(types.SET_TICKET_PREVIEW_STATUS, true);
  },
  hideTicketPreview({ commit }) {
    commit(types.SET_TICKET_PREVIEW_DATA);
    commit(types.SET_TICKET_PREVIEW_STATUS, false);
  },
  // eslint-disable-next-line
  mapTicketForPreview({ }, { ticket, ticketVersion }) {
    eventBus.$emit('publishToTicketPreview', betslipUtility.mapTicketForPreview(ticket));
    const previewTicket = ticketMapper.mapTicket(ticket, ticketVersion, 'Retail');
    return previewTicket;
  },
  /**
   * Show a dialog with an message and optional action (GGateway event)
   */
  sendGGMessage({ commit, dispatch, getters }, payload) {
    if (payload.id && !payload.delay) {
      // If notification is already active, avoid saving it
      if (
        getters.getGameGetter({ getter: 'activeNotifications' }).some((notification) => notification.id === payload.id)
      )
        return;
      dispatch('runAction', {
        action: 'addActiveNotification',
        payload,
      });
    }

    const notification = {
      action: 'Dialog.Show',
      data: {
        action: '7S:Dialog.Show',
        message: payload.message,
        type: payload.type || 'warning',
        delay: payload.delay,
        id: payload.id || null,
      },
    };
    commit(types.SEND_GG_MESSAGE, notification);
  },
  closeGGMessage({ commit, dispatch, getters }, payload) {
    if (
      payload &&
      payload.id &&
      !getters
        .getGameGetter({ getter: 'activeNotifications' })
        .some((notification) => notification.id.includes(payload.id))
    )
      return;

    const notification = {
      action: 'Dialog.Close',
      data: {
        id: payload?.id || null,
      },
    };

    // Delete notification permanently
    if (payload?.deleteNotification) {
      dispatch('runAction', {
        action: 'removeActiveNotification',
        payload: payload.id,
      });
    }

    commit(types.SEND_GG_MESSAGE, notification);
  },
  setTicketAction({ commit }, payload) {
    commit(types.SET_TICKET_ACTION, payload);
  },
  async handleTicketRebet({ getters, dispatch }, ticket) {
    eventBus.$emit('resetInput');
    await dispatch('clearBetslip');
    dispatch('ticketRebet', { bets: ticket.bets, activeGame: getters.currentActiveGame });
    setTimeout(() => {
      eventBus.$emit('focusTotalPayment');
    }, 0);
  },
  ticketRebet({ dispatch, getters }, { bets, activeGame }) {
    dispatch('runAction', {
      activeGame,
      action: 'rebetTicket',
      payload: bets,
    });
    const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
    dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: defaultShortcut?.shortcut },
    });
  },
  setTranslations({ commit }, payload) {
    commit(types.SET_TRANSLATIONS, payload);
  },
  setTicketActionSuccessful({ commit }, payload) {
    commit(types.SET_TICKET_ACTION_SUCCESSFUL, payload);
  },
  //* COMMON ACTIONS
  // SLAVE.LOAD MESSAGE AND AUTH CHANGED
  async handleParentMessageLoad({ commit, dispatch }, payload) {
    const { action } = payload;
    switch (action) {
      case 'Slave.Load': {
        const { autoPayout, payoutConfirmation, payoutConfirmationReprint } = payload.data.settings;
        const { device, user, betshop, deprecated, company } = payload.data;
        commit(types.SET_DEVICE_UUID, device);
        commit(types.SET_BETSHOP_UUID, betshop.id);
        commit(types.SET_COMPANY_ID, company.id);
        commit(types.SET_COMPANY_NAME, company.name);
        commit(types.SET_DEVICE_TOKEN, deprecated.device?.token);
        commit(types.SET_AUTO_PAYOUT, autoPayout);
        commit(types.SET_PAYOUT_CONFIRMATION, payoutConfirmation);
        commit(types.SET_PAYOUT_CONFIRMATION_REPRINT, payoutConfirmationReprint);
        commit(types.SET_CLIENT_APP_MODULES, deprecated.clientAppModules);
        dispatch('setCmsWidgetSettings', deprecated.cms.appSettings);
        dispatch('mapActiveProducts');
        // FALLBACK FOR CONFIG SERVICE SOMETIMES NOT GETTING USER
        commit(types.SET_OPERATOR_PERMISSIONS);
        commit(types.SET_USER_TOKEN, user.auth.token);
        break;
      }
      case 'User.AuthorizationChanged': {
        commit(types.SET_USER_TOKEN, payload.data.token);
        break;
      }

      default:
    }
    await dispatch('initProductApps');
  },
  // Common Gravity Gateway messages
  async handleBusMessages({ getters, dispatch }, payload) {
    // console.log('FFF handleBusMessages COMMON ::: ', payload);
    const { eventName } = payload;
    switch (eventName) {
      case 'Slave.Shown':
      case 'Slave.Unmute': {
        await dispatch('setActiveGame', payload.productId);

        const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
        await dispatch('runAction', {
          action: 'handleGameShortcuts',
          payload: { key: defaultShortcut?.shortcut },
        });
        break;
      }
      case 'UI.Show': {
        await dispatch('handleGameSwitch', payload.productId.toLowerCase());
        break;
      }
      case 'Master.Event':
        if (payload.event === 'keydown') dispatch('handleMasterEvent', payload);
        break;
      case 'Widget.Event':
        if (payload.data.event === 'click' || payload.data.shortcut)
          dispatch('handleWidgetEvent', payload.data.shortcut);
        break;
      case 'Betslip.Blocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'remove',
        });
        break;
      case 'Tickets.Checked': {
        const activeGame = payload.data?.ticket?.product?.toLowerCase();
        dispatch('runAction', { action: 'handleTicketsCheckEvent', payload, activeGame });
        break;
      }
      case 'Tickets.GetActions':
        {
          // Handle right-click data on the ticket list
          const { ticket } = payload.data;
          const ticketStatus = ticket.localStatus?.toLowerCase() || ticket?.status?.value?.toLowerCase() || '';
          const productName = ticket.product.toLowerCase();

          // Create object that has available list of actions for selected ticket
          const filteredActions = getters
            .getAvailableTicketActions(productName)
            .filter((obj) => (ticketStatus ? obj.status === ticketStatus : obj.action.includes('checkStatus')))
            .flatMap((obj) => obj.action)
            .reduce((acc, action) => {
              let active = true;
              if (action === 'copy') active = getters.canPrintCopy(productName);
              return { ...acc, [action]: { active } };
            }, {});
          payload.resolve({ actions: filteredActions });
        }
        break;
      // case 'Tickets.Payout': {
      //   // Automatic payout won tickets
      //   const { ticket } = payload.data;
      //   const ticketData = {
      //     action: 'Tickets.Payout',
      //     data: { ticket },
      //   };
      //   busService.sendMessageAsync(ticketData.action, ticketData.data);
      //   break;
      // }
      case 'Tickets.ReBet': {
        const { ticket } = payload.data;
        dispatch('handleTicketRebet', ticket);
        break;
      }
      case 'Tickets.ReCheck': {
        const { ticket } = payload.data;
        dispatch('setTicketAction', ticket.action);
        if (ticket.id && ticket.id !== '-') {
          const productName = ticket.product.toLowerCase();
          const localGameConfig = getters.getGameGetter({ specificGame: productName, getter: 'localGameConfig' });
          await ticketAPI
            .checkTicketBarcode(ticket.id, localGameConfig.ticketType)
            .then((response) => {
              ticketCheck.stopTicketChecker(payload.ticket);
              // add ticket pin if it exists
              // eslint-disable-next-line no-param-reassign
              response.productTicket.ticketPin = payload.data?.ticket.ticketPin ? payload.data?.ticket.ticketPin : null;
              successTicketHandler(response);
            })
            .catch((error) => {
              errorTicketHandler(error, ticket);
            });
        } else {
          eventBus.$emit('ticketRequestIdCheck', ticket);
        }
        break;
      }
      case 'Tickets.PrintCopy': {
        const { ticket } = payload.data;
        const ticketData = {
          action: ticket.action,
          ticket,
        };
        eventBus.$emit('printTemplate', ticketData);
        break;
      }
      case 'Tickets.PayingSentSuccess':
        await dispatch('clearBetslip');
        eventBus.$emit('resetInput');
        eventBus.$emit('focusFirstBettingInput');
        dispatch('gamesBetslip/setIsPayinButtonDisabled', false, { root: true });
        // This is currently implemented to clear stake per bet input for crash cash
        // It will need to be adjusted for other games
        break;
      case 'Tickets.PayingFailed':
        dispatch('gamesBetslip/setIsPayinButtonDisabled', false, { root: true });
        break;
      default:
    }
  },
  async handleGameSwitch({ state, getters, commit, dispatch }, newGame) {
    eventBus.$emit('publishToTicketPreview', betslipUtility.mapBetsForPreview()); // Handle switching from NGS frame to Games

    if (state.ticketPreviewActive) commit(types.SET_TICKET_PREVIEW_STATUS, false);

    if (state.gamesState[newGame]?.loading) {
      commit(types.SET_AFTERLOAD_ACTIVE_GAME, newGame);
      // UI SHOW IS SENT AFTER SLAVE.LOADED MESSAGE BUT BEFORE SOCKET SET STATE MESSAGE
      // SOCKET STATE MESSAGE MARKS THE END OF LOADING STATE FOR THE GAME
      const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
      await dispatch('runAction', {
        action: 'handleGameShortcuts',
        payload: { key: defaultShortcut?.shortcut },
      });
      return;
    }
    const { currentActiveGame } = state;
    const newGameConfig = getters.getGameGetter({
      specificGame: newGame,
      getter: 'localGameConfig',
    });
    dispatch('gamesBetslip/setConfig', newGameConfig.betslipConfiguration);
    dispatch('gamesBetslip/setMinBetPayment', newGameConfig.betslipConfiguration.rules.minBetAmount.value);
    if (currentActiveGame.toLowerCase() !== newGame) {
      dispatch('handleBetslipBetsSwitch', newGame);
      dispatch('handleNotificationsSwitch', newGame);
      // Handle deactivate edit bet mode
      dispatch('deActivateEditBetMode');
      eventBus.$emit('deactivateEditMode');
    }

    await dispatch('setActiveGame', newGame);

    const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: defaultShortcut?.shortcut },
    });
  },
  async initProductApps({ commit, state, dispatch }) {
    await dispatch('getTranslations');
    for (const game of state.activeProducts) {
      await dispatch('initApp', game.productName.toLowerCase());
      await busService.init(game);

      if (state.afterloadActiveGame) dispatch('handleGameSwitch', state.afterloadActiveGame);
    }
    dispatch('updateGameShortcuts');
    dispatch('preventDefaultShortcutBehavior');
    commit(types.SET_APP_LOADED, true);
    commit(types.SET_AFTERLOAD_ACTIVE_GAME, null);

    if (busService) busService.sendMessage('Slave.Loaded', {});
  },
  async updatePrintedTicketsByTicketUpdate({ commit, getters }, ticket) {
    const currentTimestamp = Date.now();
    const { ticketsPrintedByTicketUpdate } = getters;
    // CLEAR ALL TICKETS THAT ARE OLDER THAN MINUTE
    const filteredTickets = ticketsPrintedByTicketUpdate.filter((ticket) => {
      return currentTimestamp - ticket.timestamp < 60000;
    });
    commit(types.UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE, filteredTickets);

    // ADD THIS TICKET TO ARRAY OF TICKETS
    commit(types.ADD_PRINTED_TICKET_BY_TICKET_UPDATE, ticket);
  },
  async initApp({ dispatch }, game) {
    if (game) {
      await dispatch('setActiveGame', game);
    } else {
      await dispatch('handleGameInitError', { game, reason: 'game_not_supported' });
      return;
    }
    await dispatch('registerStoreModule');
    await dispatch('setLocalGameConfig');
    await dispatch('initializeConfigWithRetry', game);
    // await dispatch('formatBusServiceAdapters');
  },
  setActiveGame({ commit }, currentActiveGame) {
    commit(types.SET_ACTIVE_GAME, currentActiveGame.toLowerCase());
  },
  registerStoreModule({ state }) {
    const gameModule = modules[`${state.currentActiveGame}Module`];
    if (!this.hasModule(state.currentActiveGame)) {
      this.registerModule(state.currentActiveGame, gameModule);
    }
  },
  setLocalGameConfig({ state, commit }) {
    commit(types.SET_BETTING_CONFIG, localGameConfig[state.currentActiveGame]);
  },
  async initializeConfigWithRetry({ state, dispatch, commit }, game) {
    try {
      await dispatch('initConfig', game);
    } catch (error) {
      await dispatch('handleGameInitError', { game, reason: 'config_error' });
      commit(types.SET_GAME_LOADING, { game, loading: false });
    }

    const { configLoaded, retryAttempts } = state.gamesState[game];

    if (configLoaded) {
      // If config is retrieved, proceed with betslip and bus initialization
      await dispatch('setBetslipConfig', game);
      await dispatch('overrideShortcuts');
      await dispatch('overrideBetsConfig');
      await dispatch('handleSpecialBetviews');
      await dispatch('updateShortcutsEnabledStatus');

      if (retryAttempts) {
        const gameData = state.activeProducts.find((p) => p.productName === game);
        if (gameData) await busService.init(gameData);
      }
    } else {
      if (retryAttempts === 4) {
        commit(types.SET_GAME_LOADING, { game, loading: false });
        return;
      } else {
        setTimeout(async () => {
          commit(types.CONFIG_RETRY_ATTEMPT_INCREMENT, game);
          await dispatch('initializeConfigWithRetry', game);
        }, 5000 * retryAttempts);
      }
    }
  },
  async initConfig({ dispatch, commit, state }, game) {
    const activeProduct = state.activeProducts.find((product) => product.productName === game);

    const configService = new ConfigurationService({
      requiredPaths: [],
      environment: process.env.VUE_APP_ENVIRONMENT,
      applicationName: 'Retail',
      channel: 'RETAIL',
      productName: activeProduct.productName,
      productId: activeProduct.productId,
      ui: tenantId,
      locale: locale,
      keepStaticConfigStructure: true,
    });
    if (state.staticConfigFetched) delete configService.options.ui;
    let config;

    try {
      config = await configService.getConfiguration();
    } catch (error) {
      console.error('Failed to fetch config for ' + game);
      return;
    }

    // Call static service only once
    if (config.ui.config) {
      commit(types.SET_STATIC_CONFIG, config.ui.config);
    }
    // eslint-disable-next-line no-console
    console.log(' Games Shop V2 Config Gateway ', config);
    commit(types.SET_USER_TOKEN, config.user?.token);
    await dispatch('setProductConfig', { config, game });
    await dispatch('updateBetsConfig', config.bets);
    await dispatch('runAction', { action: 'setAdditionalGameConfig', payload: config });
    await dispatch('mapStaticConfigPerGame');
    await dispatch('setConfigLoaded', {
      game: activeProduct.productName,
      configLoaded: true,
    });
  },
  setBetslipConfig({ state, dispatch, getters, commit }, game) {
    const { rules, taxes } = getters.config;
    const configuration = getters.getBetslipConfiguration(game);
    // console.log(rules); // TODO
    const { localGameConfig, config, getGameGetter } = getters;
    const disableFutureIfMultiticket = getGameGetter({ getter: 'disableFutureIfMultiticket' });
    if (localGameConfig.additionalBetslipConfig) {
      if (taxes) {
        if (!taxes.payin || !taxes.payin.policy) {
          taxes.payin.hideTax = true;
        }
        taxes.payout.hideTax = true;
      }
      // TODO CONNECT TO STATIC
      const betslipConfig = {
        betLayout: configuration?.betLayout ?? 'A',
        ticketTypes: configuration?.ticketTypes ?? ['single'],
        rules: rules,
        taxes: taxes,
        isPossibleWinActive: false,
        isPaymentBetAllowed: configuration?.isPaymentBetAllowed ?? true,
        isPaymentPerBetAllowed: configuration?.isPaymentPerBetAllowed ?? true,
        isFuturePerBetAllowed: true,
        isFuturePerTicketAllowed: configuration?.isFuturePerTicketAllowed ?? false,
        isTicketCancelAllowed: true,
        isTicketRebetAllowed: configuration?.isTicketRebetAllowed ?? true,
        currency: 'BAM', // TODO THIS IS WRONG CHANGE THIS
        futureRounds: range(0, config.futureBets?.value ?? 10), // 10 is fixed for Crash Cash
        minIncrement: 0.01,
        showNotification: false,
        channelType: 'Retail',
      };
      // dispatch('setTaxes', taxes);
      if (disableFutureIfMultiticket) betslipConfig.isFuturePerTicketAllowed = false;
      localGameConfig.betslipConfiguration = betslipConfig;

      const defaultBetslipValues = {
        futureRounds: 1,
        paymentPerBet: '',
        totalPayment: rules.minBetAmount.value.toFixed(2),
      };
      dispatch('runAction', {
        action: 'setBetslipFooterValues',
        activeGame: game,
        payload: defaultBetslipValues,
      });
      commit(types.SET_BETSLIP_FOOTER_VALUES, defaultBetslipValues);
      dispatch('gamesBetslip/setActiveTicketType', getters['gamesBetslip/activeTicketTypes'][0]);

      // In case config is late and we opened the game already, default betslip is immediately overriden
      if (state.currentActiveGame === game) {
        dispatch('gamesBetslip/setConfig', localGameConfig.betslipConfiguration);
      }
    }
  },
  handleGameInitError({ commit }, { reason, game }) {
    const reasons = {
      subscription_not_active: 'Subscription not active',
      subscription_deactivated: 'Subscription deactivated',
      config_error: 'Config error',
      game_not_supported: 'Game not supported',
      socket_service_error: 'There has been an error with socket service',
    };
    commit(types.SET_GAME_NOT_ACTIVE, { errorMessage: reasons[reason], game });
    commit(types.SET_APP_SHOULD_CONTINUE_INIT, false);
  },
  addActiveBusAdapters({ commit, state }, adapters) {
    adapters.forEach((newAdapter) => {
      if (!state.activeBusAdapters.some((activeAdapter) => activeAdapter.name === newAdapter.name)) {
        commit('ADD_ACTIVE_ADAPTER', newAdapter);
      }
    });
  },
  /**
   * Action that calls active game action first if it exists
   * Otherwise calls common action
   * CAUTION: Don't call the action from same name common action (it will loop itself)
   */
  runAction({ dispatch, state }, { action, payload = null, activeGame = state.currentActiveGame }) {
    const availableActions = this._actions;
    const activeGameAction = `${activeGame}/${action}`;
    const commonAction = action;
    if (availableActions[activeGameAction]) {
      dispatch(activeGameAction, payload);
    } else if (availableActions[commonAction]) {
      dispatch(commonAction, payload);
    } else {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`[GAMES-SHOP] ACTIONS ${commonAction} AND ${activeGameAction} ARE NOT AVAILABLE!`);
      }
    }
  },
  setProductConfig({ commit }, payload) {
    commit(types.SET_PRODUCT_CONFIG, payload);
  },
  updateGameShortcuts({ state, getters }) {
    const { activeProducts, sevenProductsMapper } = state;
    forEach(activeProducts, (product) => {
      const sevenProductId = sevenProductsMapper[product.productName];
      const gameShortcuts = getters.getGameGetter({ specificGame: product.productName, getter: 'shortcuts' });
      const shortcuts = sortShortcuts(gameShortcuts);
      const data = {
        productId: sevenProductId,
        shortcuts,
      };
      busService.sendMessage('Widget.UpdateProductSettings', data);
    });
  },
  overrideShortcuts({ state, getters }) {
    const staticConfig = getters.getGameGetter({ specificGame: state.currentActiveGame, getter: 'configFromStatic' }); // TODO
    const shortcutsOverrides = staticConfig?.shopBetShortcuts;
    const quickBetslipModeAllowed = getters.getGameGetter({ getter: 'quickBetslipModeAllowed' });
    const quickBetslipModeShortcut = getters.getGameGetter({ getter: 'quickBetslipModeShortcut' });

    if (!shortcutsOverrides) return;
    // UPDATE GAME SHORTCUTS
    const { shortcuts, bettingInputs } = getters.localGameConfig;

    shortcutsOverrides.forEach((override) => {
      Object.entries(shortcuts).forEach(([key, value]) => {
        if (value.action === override.action) {
          // Update bet shortcut
          forEach(bettingInputs, (bettingInput) => {
            if (bettingInput.shortcut === value.shortcut) {
              bettingInput.shortcut = override.shortcut;
            }
          });
          // Create new shortcut and replace the existing one
          let newShortcut = shortcuts[key];
          newShortcut = { ...newShortcut, ...override };

          // Check quickBetslipModeAllowed condition
          if (override.action === quickBetslipModeShortcut.action) {
            newShortcut.active = quickBetslipModeAllowed; // Enable/Disable based on the flag
          }
          const newKey = override.shortcut || key;
          delete shortcuts[key];
          shortcuts[newKey] = newShortcut;
        }
      });
    });
  },
  async addToBetslip({ getters, dispatch }) {
    // disableFutureIfMultiticket: forbid adding bets if first bet is on future rounds
    const ticket = getters['gamesBetslip/ticket'];
    const { editBetModeActive } = getters;
    const disableFutureIfMultiticket = getters.getGameGetter({ getter: 'disableFutureIfMultiticket' });
    if (disableFutureIfMultiticket && ticket.length && ticket[0].numEvents > 1 && !editBetModeActive) {
      dispatch('sendGGMessage', {
        message: getters.getTranslation('disable_future_on_multiticket'),
        type: 'warning',
        delay: 3000,
      });
      return;
    }

    const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
    const betInputData = getters.getGameGetter({ getter: 'betInputData' });
    const bets = betslipUtility.createBet(betInputData);
    const quickBetslipMode = getters.getGameGetter({ getter: 'quickBetslipModeActive' });
    // check if edit bet is active if so update bet rather than adding it to betslip
    if (editBetModeActive) {
      const { outcome, numEvents, outcomeValue, betId, combinations, market, odd = null, racers = null } = bets[0];

      const eventDisplayId = getters.getGameGetter({ getter: 'eventDisplayId' });
      const roundNumber = getters.getGameGetter({ getter: 'eventInProgress' }) ? +eventDisplayId + 1 : +eventDisplayId;

      const updatedBet = {
        ...getters.betEditBet,
        outcome,
        numEvents,
        outcomeValue,
        betId,
        combinations,
        market,
        odd,
        racers,
        locked: false,
        roundNumber,
      };
      await dispatch('gamesBetslip/updateBet', updatedBet);
      await dispatch('deActivateEditBetMode');
      eventBus.$emit('deactivateEditMode');
    } else {
      for (let i = 0; i < bets.length; i++) {
        await dispatch('gamesBetslip/addBet', bets[i]);
      }
    }
    eventBus.$emit('resetInput');

    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: defaultShortcut?.shortcut },
    });
    dispatch('runAction', {
      action: 'updateFuture',
      payload: 1,
    });

    if (quickBetslipMode) {
      // TODO make a through flow diagram and try to fix all these setTimeout quick fixes
      setTimeout(() => {
        eventBus.$emit('focusTotalPayment');
      }, 0);
    }
  },
  async handleBetslipBetsSwitch({ getters, dispatch, commit }, newGame) {
    const ticket = getters['gamesBetslip/ticket'];
    const futureRounds = getters['gamesBetslip/futureRounds'];
    const paymentPerBet = getters['gamesBetslip/paymentPerBet'];
    const totalPayment = Number(getters['gamesBetslip/totalPayment']).toFixed(2);
    const newTicket = getters.getGameGetter({ getter: 'betslipBets', specificGame: newGame });

    // Save footer values for current game
    dispatch('runAction', {
      action: 'setBetslipFooterValues',
      payload: {
        futureRounds,
        paymentPerBet,
        totalPayment,
      },
    });

    // Save bets for current game
    if (Array.isArray(ticket)) {
      await dispatch('runAction', {
        action: 'storeBetslipTicket',
        payload: ticket,
      });
      commit(types.CLEAR_GAMES_BETSLIP_TICKET);
    }

    // Show new game bets if there are any
    if (Array.isArray(newTicket)) {
      commit(types.SET_BETSLIP_TICKET, newTicket);
      dispatch('runAction', {
        action: 'clearBetslipTicket',
        activeGame: newGame,
      });
    }

    // Show footer values of new game
    commit(
      types.SET_BETSLIP_FOOTER_VALUES,
      getters.getGameGetter({ getter: 'betslipFooterValues', specificGame: newGame }),
    );

    // Revalidate betslip
    dispatch('gamesBetslip/validateAllBets');
    dispatch('gamesBetslip/validateTotalPayment');
    dispatch('gamesBetslip/setIsManualMode', false);
  },
  async handleNotificationsSwitch({ getters, dispatch, commit }, newGame) {
    // deletNotification is false to hide current game notifications, but not delete them
    getters
      .getGameGetter({ getter: 'activeNotifications' })
      .forEach((notification) => dispatch('closeGGMessage', { id: notification.id, deleteNotification: false }));

    getters.getGameGetter({ getter: 'activeNotifications', specificGame: newGame }).forEach((data) => {
      const notification = {
        action: 'Dialog.Show',
        data,
      };
      // Using SEND_GG_MESSAGE since notifications are stored but not showing
      commit(types.SEND_GG_MESSAGE, notification);
    });
  },
  // UPDATE BETS FROM BACKEND GAME CONFIG
  // Betting input is updated because the bet type is disabled on the backend
  updateBetsConfig({ getters }, bets) {
    if (!bets) return;
    const { localGameConfig } = getters;
    const { bettingInputs } = localGameConfig;
    // UPDATE BETS ( ENABLE/DISABLE )
    forEach(bettingInputs, (bettingInput) => {
      forEach(bets, (bet) => {
        if (bettingInput.betId === bet.id && bettingInput.enabled !== bet.value) {
          bettingInput.enabled = bet.value;
        }
      });
    });
  },
  // UPDATE BETS FROM GAMES STATIC
  // Betting input is updated because there is an override in the games static
  overrideBetsConfig({ state, getters }) {
    const staticConfig = getters.getGameGetter({ specificGame: state.currentActiveGame, getter: 'configFromStatic' });
    const betsConfigOverride = staticConfig?.shopBetsConfig;
    if (!betsConfigOverride) return;
    const { bettingInputs } = getters.localGameConfig;

    betsConfigOverride.forEach((override) => {
      bettingInputs.forEach((bettingInput) => {
        if (bettingInput.clientId === override.clientId && bettingInput.enabled !== override.enabled) {
          bettingInput.enabled = override.enabled;
        }
      });
    });
  },
  updateShortcutsEnabledStatus({ getters }) {
    const { bettingInputs, shortcuts, betslipConfiguration } = getters.localGameConfig;
    const { isFuturePerTicketAllowed, isPaymentPerBetAllowed, isPaymentBetAllowed } = betslipConfiguration;
    const editBetModeFeatureAllowed = getters.getGameGetter({ getter: 'editBetModeFeatureAllowed' });

    bettingInputs.forEach((bettingInput) => {
      Object.values(shortcuts).forEach((shortcut) => {
        if (shortcut.id === bettingInput.clientId && shortcut.active !== bettingInput.enabled) {
          shortcut.active = bettingInput.enabled;
        }
      });
    });

    // Shortcuts related to betslip configuration
    const shortcutOverrides = {
      editFuturePerTicket: isFuturePerTicketAllowed,
      editStakePerBet: isPaymentPerBetAllowed,
      focusFirstBet: isPaymentBetAllowed,
      toggleEditMode: editBetModeFeatureAllowed,
      deleteBet: editBetModeFeatureAllowed,
    };

    const shortcutsArray = Object.values(shortcuts);
    Object.entries(shortcutOverrides).forEach(([key, value]) => {
      const shortcut = shortcutsArray.find((shortcut) => shortcut.action === key);
      if (shortcut) shortcut.active = value;
    });
  },
  handleSpecialBetviews({ getters, dispatch }) {
    const macroShortcutsEnabled = getters.getGameGetter({ getter: 'macroShortcutsEnabled' });
    if (macroShortcutsEnabled) dispatch('handleMacroShortcuts');
  },
  handleMacroShortcuts({ getters }) {
    const bettingInputs = getters.getGameGetter({ specificGame: 'luckysix', getter: 'localGameConfig' }).bettingInputs;
    const macroShortcutBettingInput = bettingInputs.filter((bettingInput) => bettingInput.clientId === 12)[0];
    macroShortcutBettingInput.enabled = true;
  },
  async clearBetslip({ dispatch }) {
    await dispatch('gamesBetslip/clearBetslip');
  },
  // Active products are only those that are active on the betshop,
  // supported by this project and set to load from this project (Widget.${game}...frameId === 'Games')
  mapActiveProducts({ state, commit }) {
    const { clientAppModules, supportedActiveProducts, widgetSettings } = state;
    const activeProducts = [];
    forEach(clientAppModules, (clientAppModule) => {
      const widgetSetting = widgetSettings[`widget.${clientAppModule.id}`];
      const frameId = widgetSetting?.source[0].config.frameId;
      if (
        supportedActiveProducts.some((product) => product === clientAppModule.id.toLowerCase()) &&
        frameId === 'Games'
      ) {
        activeProducts.push({
          productName: clientAppModule.id.toLowerCase(),
          productId: clientAppModule.cpvUuid,
        });
      }
    });
    commit(types.SET_ACTIVE_PRODUCTS, activeProducts);
    commit(types.SET_GAMES_STATE, activeProducts);
  },
  handleFutureBetsOnMultiticket({ getters, rootGetters, dispatch }) {
    const numberOfBets = rootGetters['gamesBetslip/numberOfBets'];
    const disableFutureIfMultiticket = getters.getGameGetter({ getter: 'disableFutureIfMultiticket' });
    if (
      disableFutureIfMultiticket &&
      ((getters.editBetModeActive && numberOfBets > 1) || (!getters.editBetModeActive && numberOfBets > 0))
    ) {
      dispatch('sendGGMessage', {
        message: getters.getTranslation('disable_future_on_multiticket'),
        type: 'warning',
        delay: 3000,
      });
      return false;
    }
    return true;
  },
  updateBetEvent({ state, getters, rootGetters, dispatch }, { game, roundNumber }) {
    if (game === state.currentActiveGame) {
      const ticket = rootGetters['gamesBetslip/ticket'];
      if (ticket.length) {
        ticket.forEach((bet) => {
          dispatch('gamesBetslip/updateBet', {
            ...bet,
            roundNumber,
          });
        });
        return;
      }
    }
    const storedTicket = getters.getGameGetter({ getter: 'betslipBets', specificGame: game });
    if (storedTicket.length) {
      dispatch('runAction', {
        action: 'updateBetslipTicket',
        activeGame: game,
        payload: {
          roundNumber,
        },
      });
    }
  },
  setConfigLoaded({ commit }, payload) {
    commit(types.SET_CONFIG_LOADED, payload);
  },
  setBusLoaded({ commit }, payload) {
    commit(types.SET_BUS_LOADED, payload);
  },
  async getTranslations({ state, dispatch }) {
    let translations = {};
    const crashCashTranslations = await crashTranslationsPackage.exportTranslations(locale, {
      companyName: state.companyName,
      companyUuid: tenantId,
      env: process.env.NODE_ENV,
    });
    const LuckySixTranslations = await luckySixTranslationsPackage.exportTranslations(locale, {
      companyName: state.companyName,
      companyUuid: tenantId,
      env: process.env.NODE_ENV,
    });
    const RacersTranslations = await racersTranslationsPackage.exportTranslations(locale, {
      companyName: state.companyName,
      companyUuid: tenantId,
      env: process.env.NODE_ENV,
    });
    translations = { ...crashCashTranslations, ...LuckySixTranslations, ...RacersTranslations };
    const mappedTranslations = await dispatch('mapTranslations', translations);
    // TODO TEMP FIX UNTIL ALL TRANSLATION KEYS FROM SDK ARE UPDATED
    dispatch('setTranslations', { ...translations, ...mappedTranslations });
  },
  // TODO TEMP FIX UNTIL ALL TRANSLATION KEYS FROM SDK ARE UPDATED
  // eslint-disable-next-line
  mapTranslations({}, translations) {
    const mappedTranslations = {};
    for (const translation in translations) {
      if (Object.prototype.hasOwnProperty.call(translations, translation)) {
        mappedTranslations[`general_${translation}`] = translations[translation];
      }
    }
    return mappedTranslations;
  },
  preventDefaultShortcutBehavior({ state, getters, commit }) {
    const preventDefaultKeys = [];
    // create an array of unique shortcut keys
    for (const game of state.activeProducts) {
      const shortcuts = getters.getGameGetter({
        getter: 'localGameConfig',
        specificGame: game.productName.toLowerCase(),
      }).shortcuts;
      const keys = Object.keys(shortcuts);
      forEach(keys, (key) => {
        if (!preventDefaultKeys.includes(key)) {
          preventDefaultKeys.push(key);
        }
      });
    }
    commit(types.SET_PREVENT_DEFAULT_KEYS_ARRAY, preventDefaultKeys);
  },
  async handleWidgetEvent({ dispatch }, shortcut) {
    dispatch('handleGlobalShortcuts', {
      key: shortcut,
    });
    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: shortcut },
    });
  },
  async handleMasterEvent({ dispatch }, payload) {
    dispatch('handleGlobalShortcuts', payload);
    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: payload.key },
    });
  },
  // Set widget settings only from supported products
  setCmsWidgetSettings({ state, commit }, appSettings) {
    const { supportedActiveProducts, sevenProductsMapper } = state;
    const settingsToKeep = [];
    supportedActiveProducts.forEach((product) => {
      settingsToKeep.push(`widget.${sevenProductsMapper[product]}`);
    });
    const widgetSettings = {};
    Object.entries(appSettings).map(([key, value]) => {
      settingsToKeep.forEach((settingToKeep) => {
        if (settingToKeep === key) {
          widgetSettings[key] = value;
        }
      });
    });
    commit(types.SET_CMS_WIDGET_SETTINGS, widgetSettings);
  },
  mapStaticConfigPerGame({ state, commit }) {
    const config = cloneDeep(state.staticConfig);
    const productName = state.currentActiveGame;
    const mergedConfig = omit(config, 'products');
    const gameConfig = omit(config?.products && config?.products[productName], 'channels');
    merge(mergedConfig, gameConfig);
    const channelConfig = config?.products ? config?.products[productName]?.channels?.retail : {};
    merge(mergedConfig, channelConfig);
    commit(types.SET_STATIC_CONFIG_PER_GAME, { game: productName, config: mergedConfig });

    // Handle rebet enable/disable for all games
    const statusesToUpdate = ['accepted', 'paidout', 'canceled', 'payedout'];
    localGameConfig[productName].availableTicketActions.forEach((obj) => {
      if (mergedConfig.betslip.isTicketRebetAllowed && statusesToUpdate.includes(obj.status)) obj.action.push('rebet');
    });
  },
  async activateEditBetMode({ commit, getters, dispatch }, { bet, betInputId }) {
    const editBetModeFeatureAllowed = getters.getGameGetter({ getter: 'editBetModeFeatureAllowed' });
    if (!editBetModeFeatureAllowed) return;
    commit(types.SET_BET_EDIT_INPUT_ID, betInputId);
    commit(types.SET_EDIT_BET_MODE_ACTIVE, true);
    commit(types.SET_BET_EDIT_BET, bet);
    const { numEvents, betId } = bet;
    // find betting input for bet id
    const { localGameConfig } = getters;
    const { bettingInputs, additionalBettingInputs } = localGameConfig;
    const betInputShortcut =
      betId < 5 && betId > 1
        ? bettingInputs.filter((bettingInput) => {
            return bettingInput.clientId === 4;
          })[0]
        : bettingInputs.filter((bettingInput) => {
            return bettingInput.betId === betId;
          })[0];

    // update betView to the selected bet type
    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: betInputShortcut.shortcut },
    }).then(async () => {
      // parse value and update betInputData
      const betInputValue = betslipUtility.mapBetsFromBetslipForCreateBet(bet);
      // update inputs with bet
      await dispatch('runAction', {
        action: 'setBetInputValue',
        payload: betInputValue,
      });

      if (numEvents > 1) {
        const additionalBettingInput = additionalBettingInputs.filter(
          (additionalBettingInput) => additionalBettingInput.clientId === 'future' && additionalBettingInput.enabled,
        );
        await dispatch('runAction', {
          action: 'setAdditionalInput',
          payload: additionalBettingInput[0],
        });
        await dispatch('runAction', {
          action: 'updateFuture',
          payload: numEvents,
        });
      }
    });
  },
  async deActivateEditBetMode({ commit, dispatch, getters }) {
    if (!getters.editBetModeActive) return;
    const defaultShortcut = getters.getGameGetter({ getter: 'defaultShortcut' });
    await dispatch('runAction', {
      action: 'handleGameShortcuts',
      payload: { key: defaultShortcut?.shortcut },
    });
    commit(types.SET_EDIT_BET_MODE_ACTIVE, false);
    commit(types.CLEAR_BET_EDIT_BET);
    await dispatch('runAction', {
      action: 'clearAdditionalInputs',
    });
    await dispatch('runAction', {
      action: 'resetBetInputValue',
    });
  },
};
