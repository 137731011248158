import { isNil } from 'lodash';

export default {
  getTranslation: (state) => (key) => state.translations[key] || key,
  autoTicketCheckTimeout: (state, getters) => getters.localGameConfig.autoTicketCheckTimeout,
  user: (state) => state.config.user,
  localGameConfig: (state) => state[state.currentActiveGame]?.localGameConfig || {},
  isGameActive: (state) => state[state.currentActiveGame]?.isGameActive,
  errorMessage: (state) => state[state.currentActiveGame]?.errorMessage,
  isBettingDisabled: (state) => state[state.currentActiveGame]?.bettingDisabled,
  adapters: (state, getters) => getters.localGameConfig.adapters,
  config: (state) => state?.[state.currentActiveGame].config,
  gameType: (state, getters) => getters.localGameConfig.bettingType,
  gameColor: (state, getters) => getters.localGameConfig.gameColor,
  betslipTypes: (state, getters) => getters.localGameConfig.betslipTypes,
  directPayin: (state, getters) => getters.localGameConfig.directPayin,
  isSystemBet: (state, getters) => getters.localGameConfig.systemBet,
  maxBetNumber: (state, getters) => getters.config.rules.maxBetNumber.value,
  minBetAmount: (state, getters) => getters.config.rules.minBetAmount.value,
  maxBetAmount: (state, getters) => getters.config.rules.maxBetAmount.value,
  isFutureBet: (state, getters) => getters.localGameConfig.futureBet,
  getAvailableTicketActions: (state) => (game) => state[game].localGameConfig.availableTicketActions,
  printLayoutType: (state) => (game) => state[game].localGameConfig.printLayoutType,
  actionTypes: (state, getters) => getters.localGameConfig.actionTypes,
  /**
   * @param {{game: String, getter: String}} payload
   * @returns
   */
  getGameGetter:
    (state, getters) =>
    ({ specificGame, getter }) => {
      const gameGetter = specificGame ? `${specificGame}/${getter}` : `${state.currentActiveGame}/${getter}`;
      if (!isNil(getters[gameGetter])) {
        return getters[gameGetter];
      } else if (!isNil(getters[getter])) {
        return getters[getter];
      } else {
        if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
          // eslint-disable-next-line no-console
          console.warn(`[GAMES-SHOP] GETTER ${getter} AND ${gameGetter} ARE NOT AVAILABLE!`);
        }
      }
    },
  configFromStatic: (state) => state.staticConfig || {},
  getBetslipConfiguration: (state, getters) => (game) =>
    getters.getGameGetter({ getter: 'configFromStatic', specificGame: game }).betslip,
  totalFutureBetsCount: (state) => state.gamesBetslip.ticket.reduce((total, bet) => total + bet.numEvents, 0),
  editBetModeActive: (state) => state.editBetModeActive,
  betEditBet: (state) => state.betEditBet,
  betEditInputId: (state) => state.betEditInputId,
  canPrintCopy: (state) => (game) => state.operatorPermissions[game]?.canPrintCopy,
  token: (state) => state.token,
  printedTicketsByTicketCheck: (state) => state.printedTicketsByTicketCheck,
  ticketChecksInProgress: (state) => state.ticketChecksInProgress,
  ticketsScheduledToBeChecked: (state) => state.ticketsScheduledToBeChecked,
  ticketsPrintedByTicketUpdate: (state) => state.ticketsPrintedByTicketUpdate,
  payoutAndRebetButtonEnabled: (state, getters) =>
    getters.getGameGetter({ getter: 'configFromStatic' }).payoutAndRebetButtonEnabled,
  rebetEnabled: (state, getters) => getters.getBetslipConfiguration(state.currentActiveGame).isTicketRebetAllowed,
};
